<template>
  <div class="settings-page">
    <v-container fluid class="py-6">
      <router-view />
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      loading_connection: false,
      code: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-page {
  background-color: #f6f6f7;
  min-height: 100%;
}
</style>
